<template>
  <div>
    <div
      class="wrapper wrapper-full-page"
      style="min-height: 70vh; background-color: rgb(17, 83, 152)"
    >
      <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
        <div class="container">
          <div class="navbar-wrapper">
            <a class="navbar-brand" href="/">
              <img
                src="static/img/white.png"
                style="max-width: 90px; position: relative"
                alt="logo"
              />
            </a>

            <button
              class="navbar-toggler"
              type="button"
              @click="showMenu = !showMenu"
              data-toggle="collapse"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-bar burger-lines"></span>

              <span class="navbar-toggler-bar burger-lines"></span>

              <span class="navbar-toggler-bar burger-lines"></span>
            </button>
          </div>

          <el-collapse-transition>
            <div
              class="navbar-collapse justify-content-end"
              id="navbar"
              v-show="showMenu"
            >
              <ul class="navbar-nav">
                <a tag="li" class="nav-item">
                  <a
                    class="nav-link"
                    href="static/whitepaper.pdf"
                    target="_blink"
                  >
                    <i class="nc-icon nc-badge"></i>
                    WHITEPAPER
                  </a>
                </a>

                <a
                  target="_blink"
                  href="https://365-stream-admin.gitbook.io/wirepayee-usdh-token/?fbclid=IwAR3BDDP8G3JwR57s-700YrKJwa43qieIqCb6YTWHSo-WTCa09FG3bPl2MyY"
                  class="nav-item"
                >
                  <a class="nav-link"> DOCUMENTATION </a>
                </a>
              </ul>
            </div>
          </el-collapse-transition>
        </div>
      </nav>

      <div class="" :data-color="backgroundColor" :class="pageClass">
        <div class="content">
          <div class="container">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import detectEthereumProvider from "@metamask/detect-provider";

export default {
  components: {
    [CollapseTransition.name]: CollapseTransition,
  },
  props: {
    pageClass: {
      type: String,
      default: "login-page",
    },
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      showConnect: false,
      address: "",
    };
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    async linkLogin() {
      const provider = await detectEthereumProvider();

      ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          this.address =
            res[0].substr(0, 4) +
            "..." +
            res[0].substr(res[0].length - 4, res[0].length);
          this.showConnect = true;
        })
        .catch((err) => {
          this.$router.push(`/login`);
        });
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>

<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.navbar-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.wrapper-full-page .navbar-toggler,
.wrapper-full-page .navbar-collapse .navbar-nav {
  margin-right: 30px;
}
.navbar-collapse .navbar-nav .nav-link {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
