// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages

import Home from "src/pages/Dashboard/Pages/Home.vue";

import Vue from "vue";
import Router from "vue-router";
import store from "../store";

Vue.use(Router);

let homePage = {
  path: "/",
  name: "Home",
  component: Home,
};

let router = new Router({
  mode: "history",
  routes: [homePage, { path: "*", component: NotFound }],
});

router.beforeEach((to, from, next) => {
  const currentUser = store.getters.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("/login");
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
