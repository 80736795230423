<template>
  <div>
    <auth-layout pageClass="register-page">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10">
          <div class="header-text"></div>
        </div>

        <div class="col-md-6 mt-3">
          <img src="static/img/white.png" class="img-fluid" />
          <h2 class="text-white" style="font-weight: 600">
            Digital money for a digital age
          </h2>
        </div>
      </div>
    </auth-layout>

    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <center>
            <div>
              <img
                src="static/img/coin/AS.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/AU.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/B.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/CS.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/E.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/F.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/HKS.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/KC.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/NZS.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/P.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/PHON.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/S.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/SS.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/W.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/Y.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
              <img
                src="static/img/coin/YY.png"
                style="width: 100px"
                class="img-fluid m-2"
              />
            </div>
            <br />
            <br />
            <a style="font-size: 26px; color: #22b1e3" class="mb-5"
              >WIREPAYEE: A Digital Token</a
            >
            <p style="font-size: 18px">
              WIREPAYEE provides protection from the volatility of
              cryptocurrencies. WIREPAYEE enables businesses including
              exchanges, wallets, payment processors, financial services and
              ATMs – to easily use Crypto-backed tokens on blockchains. By
              leveraging Blockchain technology, WIREPAYEE allows you to store,
              send and receive digital tokens person-to-person, globally,
              instantly, and securely for a fraction of the cost of
              alternatives.
            </p>
            <div style="width: 50px; height: 50px"></div>
          </center>
        </div>
      </div>
    </div>

    <section class="section pb-0 section-components">
      <div class="container mb-5">
        <center>
          <img src="static/img/USDH.png" style="width: 200px" />
          <br />

          <a
            style="font-size: 34px; color: #22b1e3; font-weight: 600"
            class="mb-4"
          >
            HASH USD ( USDH)
          </a>
          <br />
          <a style="font-size: 12px">
            Contract Adress : 0x9FE3175a01d6c95d2d134c7c6a948D06f709a7Fe
          </a>
        </center>

        <center>
          <a
            href="https://bscscan.com/token/0x9FE3175a01d6c95d2d134c7c6a948D06f709a7Fe"
            target="_blink"
          >
            <img
              src="https://pbs.twimg.com/media/FG7XLAlUUAA-11O.png"
              style="
                width: 200px;
                margin: 10px;
                border-radius: 10px;
                border-color: #22b1e3;
                border-style: solid;
                border-width: medium;
              "
            />
          </a>

          <a
            href="https://pancakeswap.finance/swap?outputCurrency=0x9FE3175a01d6c95d2d134c7c6a948D06f709a7Fe"
            target="_blink"
          >
            <img
              src="https://www.siambitcoin.com/wp-content/uploads/2021/10/PancakeSwap.jpg"
              style="
                width: 200px;
                margin: 10px;
                border-radius: 10px;
                border-color: #22b1e3;
                border-style: solid;
                border-width: medium;
              "
            />
          </a>
          <a href="https://www.365.stream/" target="_blink">
            <img
              src="https://www.365.stream/img/logo.64755377.svg"
              style="
                width: 200px;
                margin: 10px;
                border-radius: 10px;
                border-color: #22b1e3;
                border-style: solid;
                border-width: medium;
                background-color: black;
              "
            />
          </a>
          <a href="https://github.com/365stream/wirepayee" target="_blink">
            <img
              src="https://p.kindpng.com/picc/s/128-1280187_github-logo-png-github-transparent-png.png"
              style="
                width: 200px;
                margin: 10px;
                border-radius: 10px;
                border-color: #22b1e3;
                border-style: solid;
                border-width: medium;
              "
            />
          </a>
        </center>
      </div>
    </section>

    <section class="section section-lg" style="margin-top: 70px">
      <div class="container">
        <center>
          <p style="font-size: 40px; color: #22b1e3; font-weight: 500">
            We’ve combined the best of both worlds
          </p>
          <p style="font-size: 20px; color: #115398">
            Get the joint benefits of open blockchain technology and traditional
            currency
          </p>
        </center>
        <div class="row row-grid mt-5">
          <div class="col-lg-4">
            <a>
              <svg
                style="width: 40px; height: 40px"
                class="mk-svg-icon"
                data-name="mk-li-lock"
                data-cacheid="icon-5f6983d6798c5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  d="M223.992 287.398c-25.995 0-47.078 21.076-47.078 47.071 0 20.492 13.12 37.889 31.39 44.357v26.225c0 8.66 7.028 15.695 15.695 15.695 8.668 0 15.695-7.035 15.695-15.695v-26.225c18.27-6.484 31.39-23.865 31.39-44.357.002-25.996-21.089-47.071-47.092-47.071zm0 62.766c-8.652 0-15.687-7.035-15.687-15.695 0-8.645 7.035-15.68 15.687-15.68 8.66 0 15.703 7.035 15.703 15.68 0 8.659-7.043 15.695-15.703 15.695zm172.655-172.633h-47.116v-47.101c0-69.341-56.213-125.562-125.562-125.562s-125.562 56.22-125.562 125.562v47.101h-47.054c-26.003 0-47.086 21.076-47.086 47.086v235.428c0 25.995 21.083 47.086 47.086 47.086h345.294c26.002 0 47.086-21.09 47.086-47.086v-235.428c0-26.01-21.084-47.086-47.086-47.086zm-266.849-47.1c0-51.914 42.242-94.171 94.171-94.171s94.171 42.257 94.171 94.171v47.101h-15.665v-47.055c0-43.361-35.145-78.507-78.507-78.507-43.361 0-78.507 35.145-78.507 78.507v47.055h-15.665v-47.101zm156.983 47.1h-125.623v-47.055c0-34.639 28.179-62.811 62.811-62.811 34.631 0 62.811 28.172 62.811 62.811v47.055zm125.561 282.514c0 8.645-7.043 15.695-15.695 15.695h-345.294c-8.653 0-15.695-7.05-15.695-15.695v-235.428c0-8.66 7.042-15.695 15.695-15.695h345.294c8.652 0 15.695 7.035 15.695 15.695v235.428z"
                />
              </svg>
              <div class="ml-5" style="margin-top: -40px">
                <a style="font-size: 24px; color: #223a71">Stable Currency</a>
                <br />
                <a
                  >WIREPAYEE converts cash into digital currency, to pegged the
                  value to the price of national currencies</a
                >
              </div>
            </a>
          </div>
          <div class="col-lg-4">
            <a>
              <i
                class="fa fa-money"
                style="font-size: 36px; color: #223a71"
              ></i>

              <div class="ml-5" style="margin-top: -40px">
                <a style="font-size: 24px; color: #223a71">100% Backed</a>
                <br />
                <a
                  >Use WIREPAYEE as an alternative to traditional currency
                  deposit and withdrawal methods. Secure and manage customer
                  assets purely through crypto-processes. Seamlessly move
                  currency between exchanges and wallets. Ability to convert to
                  WIREPAYEE on global digital exchange.</a
                >
              </div>
            </a>
          </div>
          <div class="col-lg-4">
            <a>
              <i
                class="fa fa-search"
                style="font-size: 36px; color: #223a71"
              ></i>
              <div class="ml-5" style="margin-top: -40px">
                <a style="font-size: 24px; color: #223a71">Transparent</a>
                <br />
                <a
                  >The value of our reserves is published daily. The value of
                  our reserves matches or exceeds the value of all WIREPAYEE in
                  circulation.</a
                >
              </div>
            </a>
          </div>

          <div class="col-lg-4 mt-5">
            <a>
              <i class="fa fa-link" style="font-size: 36px; color: #223a71"></i>

              <div class="ml-5" style="margin-top: -40px">
                <a style="font-size: 24px; color: #223a71"
                  >Blockchain Technology</a
                >
                <br />
                <a
                  >The wirepayee.com platform is built on top of open blockchain
                  technologies, leveraging the security and transparency that
                  they provide.</a
                >
              </div>
            </a>
          </div>
          <div class="col-lg-4 mt-5">
            <a>
              <i
                class="fa fa-retweet"
                style="font-size: 36px; color: #223a71"
              ></i>

              <div class="ml-5" style="margin-top: -40px">
                <a style="font-size: 24px; color: #223a71"
                  >Widespread Integration</a
                >
                <br />
                <a
                  >WIREPAYEE is the most widely integrated digital-to- fiat
                  currency today. Buy, sell, and use WIREPAYEE on a global
                  digital exchange.</a
                >
              </div>
            </a>
          </div>
          <div class="col-lg-4 mt-5">
            <a>
              <i
                class="fa fa-shield"
                style="font-size: 36px; color: #223a71"
              ></i>

              <div class="ml-5" style="margin-top: -40px">
                <a style="font-size: 24px; color: #223a71">Secure</a>
                <br />
                <a
                  >WIREPAYEE’s blockchain-enabled technology delivers
                  world-class security while meeting international compliance
                  standards and regulations.</a
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section
      class="section section-lg mt-5 pt-5 pb-5"
      style="background-color: #eaebee"
    >
      <div class="container">
        <center>
          <p style="font-size: 40px; color: #22b1e3; font-weight: 500">
            Money built for the internet
          </p>
          <p style="font-size: 20px; color: #223a71">
            Whatever you can do with digital currencies, you can now do with
            digital cash.
          </p>
        </center>
        <div class="row row-grid mt-5">
          <div class="col-lg-4">
            <center>
              <!-- <img
                src="https://tether.to/wp-content/uploads/2014/07/Icon_blockchain.svg"
                :style="leafShow ? 'max-width: 86px; ' : 'max-width: 40%;'"
              />-->
              <p style="font-size: 26px; color: #22b1e3" class="mt-4">
                Blockchain Companies
              </p>
            </center>
            <br />

            <a>
              <i
                class="fa fa-check"
                style="font-size: 24px; color: #22b1e3"
              ></i>
              <div style="margin-top: -30px; margin-left: 5px" class="ml-4">
                Offer your services in a variety of digital currencies
              </div>
            </a>
            <div class="mt-3">
              <a>
                <i
                  class="fa fa-check"
                  style="font-size: 24px; color: #22b1e3"
                ></i>
                <div style="margin-top: -30px; margin-left: 5px" class="ml-4">
                  Price goods and services in a currency your customers are
                  familiar with
                </div>
              </a>
            </div>
            <div class="mt-3">
              <a>
                <i
                  class="fa fa-check"
                  style="font-size: 24px; color: #22b1e3"
                ></i>
                <div style="margin-top: -30px; margin-left: 5px" class="ml-4">
                  Reduce operating costs and times by bypassing financial
                  institutions
                </div>
              </a>
            </div>
          </div>

          <div class="col-lg-4">
            <center>
              <!-- <img
                src="https://tether.to/wp-content/uploads/2014/07/Icon_Exchanges.svg"
                :style="leafShow ? 'max-width: 86px; ' : 'max-width: 40%;'"
              />-->
              <p style="font-size: 26px; color: #22b1e3" class="mt-4">
                Exchanges
              </p>
            </center>
            <br />

            <a>
              <i
                class="fa fa-check"
                style="font-size: 24px; color: #22b1e3"
              ></i>
              <div style="margin-top: -30px; margin-left: 5px" class="ml-4">
                Use whitepayee as an alternative to traditional currency deposit
                and withdrawal methods
              </div>
            </a>
            <div class="mt-3">
              <a>
                <i
                  class="fa fa-check"
                  style="font-size: 24px; color: #22b1e3"
                ></i>
                <div style="margin-top: -30px; margin-left: 5px" class="ml-4">
                  Secure and manage customer assets purely through
                  crypto-processes, including multi-sig
                </div>
              </a>
            </div>
            <div class="mt-3">
              <a>
                <i
                  class="fa fa-check"
                  style="font-size: 24px; color: #22b1e3"
                ></i>
                <div style="margin-top: -30px; margin-left: 5px" class="ml-4">
                  Settle fiat balances between exchanges much easier and in
                  real-time
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-4">
            <center>
              <!-- <img
                src="https://tether.to/wp-content/uploads/2014/07/Icon_Individuals.svg"
                :style="leafShow ? 'max-width: 86px; ' : 'max-width: 40%;'"
              />-->
              <p style="font-size: 26px; color: #22b1e3" class="mt-4">
                Individuals/Traders
              </p>
            </center>

            <br />

            <a>
              <i
                class="fa fa-check"
                style="font-size: 24px; color: #22b1e3"
              ></i>
              <div style="margin-top: -30px; margin-left: 5px" class="ml-4">
                Seamlessly move currency between exchanges and wallets
              </div>
            </a>
            <div class="mt-3">
              <a>
                <i
                  class="fa fa-check"
                  style="font-size: 24px; color: #22b1e3"
                ></i>
                <div style="margin-top: -30px; margin-left: 5px" class="ml-4">
                  Enhance your cross-exchange and currency arbitrage strategy
                </div>
              </a>
            </div>
            <div class="mt-3">
              <a>
                <i
                  class="fa fa-check"
                  style="font-size: 24px; color: #22b1e3"
                ></i>
                <div style="margin-top: -30px; margin-left: 5px" class="ml-4">
                  Be the custodian of your own funds and eliminate exchange
                  custodial risk
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-lg m-3 mt-5 mb-5">
      <div class="container">
        <center>
          <p style="font-size: 40px; color: #22b1e3; font-weight: 500">
            Conclusion
          </p>
        </center>
        <a
          >WIREPAYEE is based on Binance Smart Chain (BSC), the most secure and
          well ­tested blockchain and public ledger in existence. We are focused
          on arranging integrations with existing businesses in the
          cryptocurrency space. Business like exchanges, wallets, merchants, and
          others.</a
        >
      </div>
    </section>

    <div class="p-5" style="background-color: #eaebee">
      <div class="container">
        <center>
          <a style="font-size: 30px">Join the modern movement of money</a>
        </center>
      </div>
    </div>

    <div class="" style="background-color: #eaebee">
      <div class="container pt-5 pb-5">
        <hr />

        <div class="row">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-8 mt-3">
                <div class="copyright">
                  &copy; {{ year }}
                  Wirepayee Limited. All rights reserved.
                </div>
              </div>

              <div class="col-md-4 justify-content-end mt-3">
                <div class="d-flex flex-row">
                  <a
                    href="https://www.facebook.com/365StreamBritain"
                    target="_blank"
                    class="m-2"
                  >
                    <i style="font-size: 22px" class="fa fa-facebook" />
                  </a>

                  <a
                    class="m-2"
                    href="https://twitter.com/365streamUK"
                    target="_blank"
                  >
                    <i style="font-size: 22px" class="fa fa-twitter" />
                  </a>

                  <a
                    href="https://medium.com/@365.stream"
                    target="_blank"
                    class="m-2"
                  >
                    <i style="font-size: 22px" class="fa fa-medium" />
                  </a>

                  <a
                    href="https://www.reddit.com/search/?q=365.stream"
                    target="_blank"
                    class="md-reddit m-2"
                  >
                    <i style="font-size: 22px" class="fa fa-reddit" />
                  </a>

                  <a
                    href="https://www.instagram.com/365.streamuk/"
                    target="_blank"
                    class="md-instagram m-2"
                  >
                    <i style="font-size: 22px" class="fa fa-instagram" />
                  </a>

                  <a href="" target="_blank" class="md-twitter m-2">
                    <i style="font-size: 22px" class="fa fa-telegram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FadeRenderTransition } from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      priceBTCUSDT: "",
    };
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    copyText(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  created() {},
};
</script>

<style></style>
